import React, { useState, useEffect } from 'react'
import './CityManager.sass'
import Spinner from '@bit/ollapp.components.spinner'
import { withRouter } from 'react-router-dom'
import PopUp from '../../../../../ui/PopUp/PopUp'
import getDoc from '../../../../../utils/db/getDoc'
import getFieldRenderObject from '../../../../../utils/forms/render/getFieldRenderObject'
import FormRender from '../../../../../utils/forms/render/FormRender'
import Button from '../../../../../ui/Button/Button'
import { createForm } from '../../../../../utils/forms/createForm'
import isFormValid from '../../../../../utils/forms/validation/isFormValid'
import getFormValues from '../../../../../utils/forms/getFormValues'
import updateDoc from '../../../../../utils/db/updateDoc'
import addDoc from '../../../../../utils/db/addDoc'

function CityManager({ ...router }) {
  const cityId = router.match.params.id

  const [form, setForm] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [showErrors, setShowErrors] = useState(false)

  useEffect(() => {
    if (cityId) {
      getDoc({ path: 'cities', docId: cityId, docIdName: 'cityId' }).then(
        (data) => {
          setForm(
            createForm({
              formPattern: {
                title: {
                  field: {
                    fieldId: 'title',
                    fieldType: 'input',
                    inputType: 'text',
                    label: 'Місто',
                    placeholder: 'Вкажіть назву міста',
                    required: true,
                  },
                  render: getFieldRenderObject(),
                },
                lat: {
                  field: {
                    fieldId: 'lat',
                    fieldType: 'input',
                    inputType: 'number',
                    label: 'Координата lat',
                    placeholder: 'Координата lat',
                    required: true,
                  },
                  render: getFieldRenderObject(),
                },
                lng: {
                  field: {
                    fieldId: 'lng',
                    fieldType: 'input',
                    inputType: 'number',
                    label: 'Координата lng',
                    placeholder: 'Координата lng',
                    required: true,
                  },
                  render: getFieldRenderObject(),
                },
              },
              formData: data,
            })
          )
        }
      )
    } else {
      setForm(
        createForm({
          formPattern: {
            title: {
              field: {
                fieldId: 'title',
                fieldType: 'input',
                inputType: 'text',
                label: 'Місто',
                placeholder: 'Вкажіть назву міста',
                required: true,
              },
              render: getFieldRenderObject(),
            },
            lat: {
              field: {
                fieldId: 'lat',
                fieldType: 'input',
                inputType: 'number',
                label: 'Координата lat',
                placeholder: 'Координата lat',
                required: true,
              },
              render: getFieldRenderObject(),
            },
            lng: {
              field: {
                fieldId: 'lng',
                fieldType: 'input',
                inputType: 'number',
                label: 'Координата lng',
                placeholder: 'Координата lng',
                required: true,
              },
              render: getFieldRenderObject(),
            },
          },
        })
      )
    }
  }, [])

  const onSubmit = () => {
    if (!isFormValid({ form })) {
      return setShowErrors(true)
    }
    setIsLoading(true)
    const values = getFormValues({ form })

    const opRef = cityId
      ? updateDoc({
          path: 'cities',
          docId: cityId,
          data: values,
        })
      : addDoc({
          path: 'cities',
          data: values,
        })

    opRef
      .then(() => router.history.push('/settings/cities'))
      .catch((e) => {
        setIsLoading(false)
        console.log(
          '🚀 ~ file: ManageCategoryPopUp.jsx ~ line 83 ~ onSubmit ~ e',
          e
        )
      })
  }

  return (
    <PopUp
      title={`${cityId ? 'Редагувати' : 'Додати нове'} місто`}
      show
      close={() => router.history.push('/settings/cities')}
    >
      <div className="CityManager-Container">
        <FormRender
          sections={[{ fields: ['title', 'lat', 'lng'] }]}
          form={form}
          setForm={setForm}
          errors={showErrors}
        />
        <div className="CityManager-Buttons">
          <Button
            title="Зберегти"
            size={40}
            theme="primary"
            onClick={onSubmit}
            isLoading={isLoading}
          />
          <Button
            title="Відмінити"
            size={40}
            theme="secondary"
            onClick={() => router.history.push('/settings/cities')}
          />
        </div>
      </div>
    </PopUp>
  )
}

export default withRouter(CityManager)
