import find from 'lodash/find'

const updateCategoriesActivityStatus = (cats, path) => {
  const categoriesClone = cats.map((c) => {
    c.isActive = false
    return c
  })
  path.forEach((p) => {
    const catToEdit = find(categoriesClone, ['categoryId', p])
    if (catToEdit) catToEdit.isActive = true
  })
  return categoriesClone
}

export default updateCategoriesActivityStatus
