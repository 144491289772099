import React, { useState } from 'react'
import './ArchiveOrderPopUp.sass'
import PopUp from '../../../../../../ui/PopUp/PopUp'
import Button from '../../../../../../ui/Button/Button'
import updateDoc from '../../../../../../utils/db/updateDoc'

function ArchiveOrderPopUp({ docId, number, close }) {
  const [isLoading, setIsLoading] = useState(false)

  const onCancel = () => {
    setIsLoading(true)
    updateDoc({
      path: 'orders',
      docId,
      data: { status: 'WCZpA64uC6lSVwnP24xq', updated: new Date() },
    }).then(() => close())
  }

  return (
    <PopUp
      show
      title="Виконати замовлення"
      className="ArchiveOrderPopUp"
      close={close}
    >
      <div className="ArchiveOrderPopUp-Container">
        <p className="Text Body-Regular-2">
          Ви справді бажаєте виконати замовлення і перевести його в архів?{' '}
          <span className="Body-Bold-2">№{number}</span>
        </p>
        <div className="Buttons">
          <Button
            title="Виконати замовлення"
            size={40}
            onClick={onCancel}
            isLoading={isLoading}
          />
          <Button
            title="Відмінити"
            size={40}
            theme="internal-link"
            onClick={close}
          />
        </div>
      </div>
    </PopUp>
  )
}

export default ArchiveOrderPopUp
