import React from 'react'
import './SideNavBar.sass'
import { NavLink } from 'react-router-dom'
import Icon from '../Icon/Icon'
import IMGSideLogo from './assets/ollapp-side-logo.svg'

function SideNavBar() {
  return (
    <div className="SideNavBar">
      <div className="SideNavBar-Logo">
        <img src={IMGSideLogo} alt="OllApp" />
      </div>
      <div className="SideNavBar-Content">
        {/* <SideNavBarLink icon="users" title="Клієнти" path="/clients" /> */}
        <SideNavBarLink icon="retweet" title="Замовл." path="/orders" />
        <SideNavBarLink icon="store" title="Партнери" path="/partners" />
        <SideNavBarLink icon="box" title="Товари" path="/products" />
        <SideNavBarLink icon="cog" title="Інше" path="/settings" />
      </div>
    </div>
  )
}

function SideNavBarLink({ title, icon, path }) {
  return (
    <NavLink
      to={path}
      className="SideNavBar-Link"
      activeClassName="SideNavBar-Link_status_active"
    >
      <Icon name={icon} />
      <p className="SideNavBar-Link-Title">{title}</p>
    </NavLink>
  )
}

export default SideNavBar
