import React from 'react'
import { Route } from 'react-router-dom'
import ParamsList from './components/ParamsList/ParamsList'

function Params() {
  return (
    <div>
      <Route path="/settings/params" exact component={ParamsList} />
      <Route
        path="/settings/params/add"
        exact
        render={() => <ParamsList newParam />}
      />
      <Route
        path="/settings/params/edit/:paramId"
        exact
        component={ParamsList}
      />
    </div>
  )
}

export default Params
