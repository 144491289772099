const buildCategoriesTree = (categories) => {
  const catPull = {}
  categories
    .filter((c) => c.parentCategory === 'root')
    .forEach((c) => {
      catPull[c.categoryId] = c
    })

  nestCategories(catPull, categories)

  return catPull
}

const nestCategories = (categoriesObj, categoriesData) => {
  for (const catId in categoriesObj) {
    const children = categoriesData.filter((c) => c.parentCategory === catId)
    categoriesObj[catId].children = {}
    children.forEach((ch) => {
      categoriesObj[catId].children[ch.categoryId] = ch
      nestCategories(categoriesObj[catId].children, categoriesData)
    })
  }
}
export default buildCategoriesTree
