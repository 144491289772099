import React from 'react'
import './ModuleNav.sass'
import { NavLink } from 'react-router-dom'

function ModuleNav({ links = [] }) {
  return (
    <div className="ModuleNav">
      {links.map((l) => (
        <NavLink
          className="ModuleNav-Link"
          activeClassName="ModuleNav-Link_theme_active"
          to={l.path}
        >
          {l.title}
        </NavLink>
      ))}
    </div>
  )
}

export default ModuleNav
