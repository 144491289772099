import React from 'react'
import './OptionsButton.sass'
import Button from '../../Button/Button'

const OptionsButton = React.forwardRef(({ clicked, icon = 'cog' }, ref) => (
  <div ref={ref} className="OptionsButton" onClick={clicked}>
    <Button size={24} icon={icon} />
  </div>
))

export default OptionsButton
