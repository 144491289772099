import React, { useState, useEffect } from 'react'
import './OrdersList.sass'
import { withRouter } from 'react-router-dom'
import { find } from 'lodash'
import Spinner from '../../../../ui/Spinner/Spinner'
import PageTitle from '../../../../ui/Page/PageTitle/PageTitle'
import PageContainer from '../../../../ui/Page/PageContainer/PageContainer'
import Table from '../../../../ui/Table/Table'
import promiseAllValues from '../../../../utils/promiseAllValues'
import getCollection from '../../../../utils/db/getCollection'
import {
  formatDateAsDayMontYearHHMM,
  formatDateForDateChooser,
} from '../../../../utils/date/dateFormat'

function OrdersList({ ...router }) {
  const [orders, setOrders] = useState(null)

  useEffect(() => {
    promiseAllValues({
      orders: getCollection({
        path: 'orders',
        docIdName: 'docId',
        orderBy: { fieldPath: 'created', orderDir: 'desc' },
      }),
      statuses: getCollection({
        path: 'config/orders/statuses',
        docIdName: 'statusId',
      }),
      shops: getCollection({ path: 'shops', docIdName: 'shopId' }),
      cities: getCollection({ path: 'cities', docIdName: 'cityId' }),
      users: getCollection({ path: 'users', docIdName: 'userId' }),
    }).then((result) => {
      setOrders(
        result.orders.map((o) => {
          const status = find(result.statuses, ['statusId', o.status])
          const shop = find(result.shops, ['shopId', o.pickupShop])
          const spot = find(shop.spots, ['spotId', o.pickupSpot])
          const city = find(result.cities, ['cityId', spot.spotCity])
          const user = find(result.users, ['userId', o.uid])

          o.statusData = status
          o.spotFullAddress = `${spot.title}, м. ${city.title}, ${spot.spotAddress}`
          o.city = city.title
          o.user = user

          return o
        })
      )
    })
  }, [])

  return !orders ? (
    <Spinner type="module" />
  ) : (
    <>
      <PageTitle title="Замовлення" counter={orders.length} />
      <PageContainer>
        <Table
          editOnDoubleClick
          tableHead={[
            ['№'],
            ['Дата'],
            ['Термін прокату'],
            ['Статус'],
            ['Локація'],
            ['Сума'],
            ['Клієнт'],
            ['', '18'],
          ]}
          tableBody={orders.map((o) => [
            { id: o.docId },
            <span>{o.orderId}</span>,
            <span>
              {formatDateAsDayMontYearHHMM(o.created.seconds * 1000)}
            </span>,
            <div>
              <p>
                {formatDateForDateChooser(
                  o.dates.start.seconds * 1000,
                  o.dates.end.seconds * 1000,
                  o.sameDay
                )}
              </p>
              {o.sameDay && (
                <p className="Body-Regular-2 OrderList-SameDay">
                  Заберу після 15:00
                </p>
              )}
            </div>,
            <span
              className="OrderStatus"
              style={{
                background: o.statusData.background,
                color: o.statusData.color,
              }}
            >
              {o.statusData.title}
            </span>,
            <span>{o.spotFullAddress}</span>,
            <div>
              <p>
                {o.price} грн.
                {o.paymentType !== 'full' ? (
                  <span className="Body-Regular-2 OrderList-SameDay">
                    {' '}
                    ({`${o.price - o.priceToPay} грн.`})
                  </span>
                ) : null}
              </p>
            </div>,
            <div className="OrderList-User">
              <p>
                {o.user.name} {o.user.lastName}
              </p>
              <p className="Body-Regular-2 OrderList-SameDay">{o.user.phone}</p>
            </div>,
            'options',
          ])}
          options={[
            {
              return: 'docId',
              type: 'edit',
              icon: 'eye',
              label: 'Відкрити',
              clicked: (orderId) => router.history.push(`/orders/${orderId}`),
            },
          ]}
        />
      </PageContainer>
    </>
  )
}

export default withRouter(OrdersList)
