import React, { useState, useEffect } from 'react'
import './ManageCategoryPopUp.sass'
import PopUp from '../../../../../ui/PopUp/PopUp'
import getDoc from '../../../../../utils/db/getDoc'
import { createForm } from '../../../../../utils/forms/createForm'
import getFieldRenderObject from '../../../../../utils/forms/render/getFieldRenderObject'
import FormRender from '../../../../../utils/forms/render/FormRender'
import Button from '../../../../../ui/Button/Button'
import isFormValid from '../../../../../utils/forms/validation/isFormValid'
import getFormValues from '../../../../../utils/forms/getFormValues'
import updateDoc from '../../../../../utils/db/updateDoc'
import addDoc from '../../../../../utils/db/addDoc'

function ManageCategoryPopUp({ categoryId, parentCategoryId, close }) {
  const [form, setForm] = useState(null)
  const [showErrors, setShowErrors] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (categoryId) {
      getDoc({
        path: 'config/products/categories',
        docId: categoryId,
        docIdName: 'categoryId',
      }).then((data) =>
        setForm(
          createForm({
            formPattern: {
              title: {
                field: {
                  fieldId: 'title',
                  fieldType: 'input',
                  inputType: 'text',
                  label: 'Назва категорії',
                  placeholder: 'Вкажіть назву категорії',
                  required: true,
                },
                render: getFieldRenderObject(),
              },
            },
            formData: data,
          })
        )
      )
    } else {
      setForm(
        createForm({
          formPattern: {
            title: {
              field: {
                fieldId: 'title',
                fieldType: 'input',
                inputType: 'text',
                label: 'Назва категорії',
                placeholder: 'Вкажіть назву категорії',
                required: true,
              },
              render: getFieldRenderObject(),
            },
          },
        })
      )
    }
  }, [])

  const onSubmit = () => {
    if (!isFormValid({ form })) {
      return setShowErrors(true)
    }
    setIsLoading(true)
    const values = getFormValues({ form })

    const opRef = categoryId
      ? updateDoc({
          path: 'config/products/categories',
          docId: categoryId,
          data: values,
        })
      : addDoc({
          path: 'config/products/categories',
          data: { ...values, parentCategory: parentCategoryId },
        })

    opRef
      .then(() => close())
      .catch((e) => {
        console.log(
          '🚀 ~ file: ManageCategoryPopUp.jsx ~ line 83 ~ onSubmit ~ e',
          e
        )
      })
  }

  return (
    <PopUp
      title={`${categoryId ? 'Редагувати' : 'Створити нову'} категорію`}
      show
      close={close}
    >
      <div className="ManageCategoryPopUp-Container">
        <FormRender
          sections={[{ fields: ['title'] }]}
          form={form}
          setForm={setForm}
          errors={showErrors}
        />
        <div className="ManageCategoryPopUp-Buttons">
          <Button
            title="Зберегти"
            size={40}
            theme="primary"
            onClick={onSubmit}
            isLoading={isLoading}
          />
          <Button
            title="Відмінити"
            size={40}
            theme="secondary"
            onClick={close}
          />
        </div>
      </div>
    </PopUp>
  )
}

export default ManageCategoryPopUp
