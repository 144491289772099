import cloneDeep from 'lodash/cloneDeep'
import * as ACTION_TYPES from '../actions/actionTypes'

const initialState = { isLoading: true, user: null }

const authReducer = (state = initialState, action) => {
  const stateClone = cloneDeep(state)
  switch (action.type) {
    case ACTION_TYPES.AUTH_SEND_USER_DATA_TO_REDUCER:
      return authSendUserDataToReducer(stateClone, action.payload)
    default:
      return state
  }
}

const authSendUserDataToReducer = (state, payload) => {
  state.user = payload
  state.isLoading = false
  return state
}

export default authReducer
