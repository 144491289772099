import React from 'react'
import './Settings.sass'
import { Route, Redirect } from 'react-router-dom'
import Params from './components/Params/Params'
// import KnowledgeBase from './components/KnowledgeBase/KnowledgeBase'
import CategoriesManager from './components/CategoriesManager/CategoriesManager'
import ModuleNav from '../../ui/ModuleNav/ModuleNav'
import Cities from './components/Cities/Cities'

function Employees() {
  return (
    <section className="Settings">
      <ModuleNav
        links={[
          { title: 'Міста', path: '/settings/cities' },
          // { title: 'Часті запитання', path: '/settings/faq' },
          { title: 'Категорії товарів', path: '/settings/categories' },
          { title: 'Параметри товарів', path: '/settings/params' },
          // { title: 'Промокоди', path: '/settings/promocodes' },
          // { title: 'Слайдер', path: '/settings/slider' },
          // { title: 'Правила оренди', path: '/settings/rent-terms' },
        ]}
      />
      <div className="Settings-Section">
        <Route exact path="/settings">
          <Redirect to="/settings/categories" />
        </Route>
        <Route path="/settings/cities" component={Cities} />
        <Route path="/settings/categories" component={CategoriesManager} />
        <Route path="/settings/params" component={Params} />
        {/* <Route path="/settings/faq" component={KnowledgeBase} /> */}
        {/* <Route path="/settings/promocodes" component={Promocodes} /> */}
        {/* <Route path="/settings/slider" component={Slider} /> */}
        {/* <Route path="/settings/payments" exact component={PaymentSettings} /> */}
        {/* <Route path="/settings/rent-terms" component={RentTerms} /> */}
      </div>
    </section>
  )
}

export default Employees
