import React from 'react'
import './Orders.sass'
import { Route } from 'react-router-dom'
import OrdersList from './components/OrdersList/OrdersList'
import OrdersManager from './components/OrdersManager/OrdersManager'

function Orders() {
  return (
    <section className="Orders">
      <Route path="/orders" exact component={OrdersList} />
      <Route path="/orders/:orderId" exact component={OrdersManager} />
    </section>
  )
}

export default Orders
