import { isEmpty } from 'lodash'
import { db } from '../../config/firebase'

const getCollection = ({ path, docIdName, orderBy, whereQueries }) => {
  let collectionRef = db.collection(path)

  if (!isEmpty(whereQueries)) {
    whereQueries.forEach((q) => {
      collectionRef = collectionRef.where(q.fieldPath, q.op, q.value)
      if (q.op !== '==') {
        collectionRef = collectionRef.orderBy(q.fieldPath, q.orderDir)
      }
    })
  }

  if (orderBy) {
    collectionRef = collectionRef.orderBy(orderBy.fieldPath, orderBy.orderDir)
  }

  return collectionRef.get().then((docs) => {
    const arr = []

    docs.forEach((d) => {
      arr.push({ ...(docIdName ? { [docIdName]: d.id } : {}), ...d.data() })
    })
    return arr
  })
}

export default getCollection
