import React, { useState, useEffect } from 'react'
import './CategoriesManager.sass'
import { db } from '@bit/ollapp.components.firebase'
import { withRouter } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import cloneDeep from 'lodash/cloneDeep'
import useCallbacks from '@bit/ollapp.components.use-callbacks'
import PageTitle from '../../../../ui/Page/PageTitle/PageTitle'
import PageContainer from '../../../../ui/Page/PageContainer/PageContainer'
import CategoryColumn from './components/CategoryColumn'
import buildCategoriesTree from './functions/buildCategoriesTree'
import updateCategoriesActivityStatus from './functions/updateCategoriesActivityStatus'

function CategoriesManager({ ...router }) {
  const [categories, setCategories] = useState(null)
  const [categoriesTree, setCategoriesTree] = useState(null)
  const [categoriesPath, setCategoriesPath] = useState([])

  useEffect(() => {
    db.collection('config/products/categories').onSnapshot((snap) => {
      let cats = []
      snap.forEach((doc) => {
        cats.push({ categoryId: doc.id, ...doc.data(), isActive: false })
      })
      const path = handlers.getCategoriesPath()
      cats = updateCategoriesActivityStatus(cats, path)
      setCategories(cats)
      setCategoriesPath(path)
    })
  }, [])

  useEffect(() => {
    if (categories) {
      setCategoriesTree(buildCategoriesTree(categories))
    }
  }, [categories])

  const handlers = useCallbacks((callbacks) => {
    callbacks.getCategoriesPath = () => {
      const curUrl = router.location.pathname
      const originUrl = router.match.path
      const curURI = curUrl.replace(originUrl, '').substring(1)
      const path = curURI.split('/')
      return path
    }
  })

  const navigateToCategory = (categoryId, pos) => {
    const path = cloneDeep(categoriesPath)
    path.splice(pos, path.length, categoryId)
    const categoriesClone = updateCategoriesActivityStatus(categories, path)

    setCategories(categoriesClone)
    setCategoriesPath(path)
    router.history.push(`/settings/categories/${path.join('/')}`)
  }

  return (
    <>
      <PageTitle title="Управління категоріями товарів" />
      <PageContainer>
        <div className="CategoriesManager">
          <div className="CategoriesManager-Container">
            <CategoryColumn
              title="Основні категорії"
              addOption
              parentCategoryId="root"
              pos={0}
              categories={
                categoriesTree &&
                Object.entries(categoriesTree).map(([k, v]) => v)
              }
              onCategoryClicked={(categoryId) =>
                navigateToCategory(categoryId, 0)
              }
            />
            {categoriesTree &&
              renderCategories(categoriesTree, 1, [], navigateToCategory)}
          </div>
        </div>
      </PageContainer>
    </>
  )
}

const renderCategories = (categoriesTree, pos, content, navigateToCategory) => {
  Object.entries(categoriesTree).forEach(([k, v]) => {
    if (v.isActive) {
      content.push(
        <CategoryColumn
          title={v.title}
          categoryId={v.categoryId}
          editOptions
          parentCategoryId={v.categoryId}
          pos={pos}
          categories={
            isEmpty(v.children)
              ? []
              : Object.entries(v.children).map(([k, v]) => v)
          }
          onCategoryClicked={(categoryId) =>
            navigateToCategory(categoryId, pos)
          }
        />
      )
      renderCategories(v.children, pos + 1, content, navigateToCategory)
    }
  })

  return content
}

export default withRouter(CategoriesManager)
