import React from 'react'
import './PopUp.sass'
import Backdrop from './Backdrop/Backdrop'
import Icon from '../Icon/Icon'

function PopUp({
  show,
  close,
  transparentBackdrop,
  icon,
  title,
  className,
  children,
}) {
  return (
    <Backdrop
      transparentBackdrop={transparentBackdrop}
      show={show}
      close={close}
    >
      <div className={['PopUp', ...(className ? [className] : [])].join(' ')}>
        <div className="PopUp-Header">
          <div className="Heder-Title">
            {icon && <Icon name={icon} />}
            <h3>{title}</h3>
          </div>
          <Icon onClick={close} name="times" />
        </div>
        <div className="PopUp-Content">{children}</div>
      </div>
    </Backdrop>
  )
}

export default PopUp
