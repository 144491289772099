import uniqid from 'uniqid'
import getFieldRenderObject from '../../../../utils/forms/render/getFieldRenderObject'

export default class ProductObject {
  constructor({ disabledFields = [] } = {}) {
    this.avatar = {
      field: {
        fieldId: 'avatar',
        fieldType: 'inputFile',
        inputType: 'file',
      },
      render: getFieldRenderObject(),
    }
    this.category = {
      field: {
        fieldId: 'category',
        fieldType: 'select',
        label: 'Категорія',
        placeholder: 'Виберіть категорію товару',
        getOptions: 'getCategoriesOptions',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.subCategory = {
      field: {
        fieldId: 'subCategory',
        fieldType: 'select',
        label: 'Підкатегорія',
        placeholder: 'Виберіть підкатегорію товару',
        getOptions: 'getSubCategoriesOptions',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.frontTitle = {
      field: {
        fieldId: 'frontTitle',
        fieldType: 'input',
        inputType: 'text',
        label: 'Назва товару (фронт)',
        placeholder: 'Вкажіть назву товару',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.backTitle = {
      field: {
        fieldId: 'backTitle',
        fieldType: 'input',
        inputType: 'text',
        label: 'Назва товару (бек)',
        placeholder: 'Вкажіть назву товару',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.description = {
      field: {
        fieldId: 'description',
        fieldType: 'textarea',
        label: 'Опис товару',
        placeholder: 'Вкажіть опис товару',
      },
      render: getFieldRenderObject(),
    }
    this.assignment = {
      field: {
        fieldId: 'assignment',
        value: [],
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.params = {
      field: {
        fieldId: 'params',
        fieldType: 'fieldset',
        fields: [
          {
            field: {
              fieldId: 'param',
              fieldType: 'select',
              getOptions: 'getParamsOptions',
              label: 'Параметр',
            },
            render: getFieldRenderObject(),
          },
        ],
        systemField: false,
      },
      render: getFieldRenderObject(),
    }
    this.options = {
      field: {
        fieldId: 'options',
        fieldType: 'fieldset',
        fields: [
          {
            field: {
              fieldId: 'avatar',
              fieldType: 'inputFile',
              label: 'Фото',
            },
            render: getFieldRenderObject(),
          },
          {
            field: {
              fieldId: 'title',
              fieldType: 'input',
              inputType: 'text',
              label: 'Назва товару',
              placeholder: 'Вкажіть назву товару',
            },
            render: getFieldRenderObject(),
          },
          {
            field: {
              fieldId: 'param',
              fieldType: 'select',
              inputType: 'text',
              label: 'Параметр',
              getOptions: 'getParamsOptions',
            },
            render: getFieldRenderObject(),
          },
          {
            field: {
              fieldId: 'id',
              fieldType: 'input',
              inputType: 'text',
              label: 'ID',
            },
            render: getFieldRenderObject(),
          },
        ],
        systemField: false,
      },
      render: getFieldRenderObject(),
    }
    this.price = {
      field: {
        fieldId: 'price',
        value: {},
      },
      render: getFieldRenderObject({ isSystem: true }),
    }

    this.created = {
      field: {
        fieldId: 'created',
        value: new Date(),
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.updated = {
      field: {
        fieldId: 'updated',
        value: new Date(),
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.version = {
      field: {
        fieldId: 'version',
        value: '1.0',
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
  }
}
