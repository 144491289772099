import React, { useState, useEffect, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import { cloneDeep, omit } from 'lodash'
import PageContainer from '../../../ui/Page/PageContainer/PageContainer'
import PageTitle from '../../../ui/Page/PageTitle/PageTitle'
import { createForm } from '../../../utils/forms/createForm'
import FormRender from '../../../utils/forms/render/FormRender'
import useSystemFieldsMethods from '../../../utils/forms/useSystemFieldsMethods'
import ProductObject from './functions/productObject'
import ProductAvatar from '../../../ui/ProductAvatar/ProductAvatar'
import ProductAssignment from './components/ProductAssignment/ProductAssignment'
import './ProductsManager.sass'
import ProductAvaliabilityManager from './components/ProductAvaliabilityManager/ProductAvaliabilityManager'
import Button from '../../../ui/Button/Button'
import getFormValues from '../../../utils/forms/getFormValues'
import syncForm from '../../../utils/forms/changed/syncForm'
import addDoc from '../../../utils/db/addDoc'
import getDoc from '../../../utils/db/getDoc'
import updateDoc from '../../../utils/db/updateDoc'
import NotificationBlock, {
  useNotificationBlock,
} from '../../../ui/NotificationBlock/NotificationBlock'
import uploadPhotos from './functions/uploadPhotos'

function ProductsManager({ ...router }) {
  const productId = router.match.params.productId || null
  const [form, setForm] = useState(null)
  console.log(
    '🚀 ~ file: ProductsManager.jsx ~ line 28 ~ ProductsManager ~ form',
    form
  )
  const methods = useSystemFieldsMethods({ form })
  const [showErrors, setShowErrors] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [nBlockSettings, setNBlockSettings] = useNotificationBlock()

  const avManagerRef = useRef()

  useEffect(() => {
    if (productId) {
      getDoc({
        path: 'products',
        docId: productId,
        docIdName: 'productId',
      }).then((data) => {
        setForm(
          createForm({
            formPattern: new ProductObject(),
            formData: data,
            methods,
          })
        )
      })
    } else {
      setForm(
        createForm({
          formPattern: new ProductObject(),
          methods,
        })
      )
    }
  }, [])

  const onSubmit = () => {
    const values = getFormValues({ form })
    values.price = avManagerRef.current.getState()
    setIsLoading(true)

    const opRef = productId
      ? updateDoc({
          path: 'products',
          docId: productId,
          data: { ...omit(values, ['avatar', 'options']), updated: new Date() },
        })
      : addDoc({
          path: 'products',
          data: { ...omit(values, ['avatar', 'options']) },
        })

    opRef.then((docId) =>
      uploadPhotos({ values, form, setForm, docId, methods })
        .then((thispId) => {
          router.history.push(`/products/edit/${thispId}`)
          setIsLoading(false)
          setNBlockSettings({
            show: true,
            theme: 'success',
            content: 'Товар успішно збережено',
          })
          setTimeout(() => setNBlockSettings({ show: false }), 3000)
        })
        .catch((error) => {
          console.log(
            '🚀 ~ file: ManageProjectPage.jsx ~ line 79 ~ onSubmit ~ error',
            error
          )
        })
    )
  }

  return (
    <>
      <PageTitle
        title={productId ? 'Редагувати товар' : 'Додати новий товар'}
        headerContent={
          <div className="PageTitle-HeaderContent-Controlls">
            <Button
              title="Назад"
              theme="internal-link"
              onClick={() => router.history.push('/products')}
              size={40}
            />
            {/* <Button
              title="Видалити"
              theme="secondary"
              onClick={() => null}
              size={40}
            /> */}
            <Button
              title="Зберегти"
              theme="primary"
              onClick={onSubmit}
              size={40}
              isLoading={isLoading}
            />
          </div>
        }
      />
      <PageContainer>
        <div className="ProductsManager">
          {nBlockSettings.show && <NotificationBlock {...nBlockSettings} />}
          <div className="ProductsManager-MainInfo">
            <ProductAvatar
              src={
                form
                  ? form.avatar.value.publicUrl || form.avatar.value.fileUrl
                  : null
              }
              syncForm={(newForm) => {
                if (form) {
                  const formClone = cloneDeep(form)
                  formClone.avatar.value = newForm.avatar.value
                  setForm(formClone)
                }
              }}
            />
            <div className="MainInfo-Container">
              <FormRender
                sections={[
                  {
                    fields: [
                      'category',
                      'subCategory',
                      'frontTitle',
                      'backTitle',
                      'description',
                    ],
                  },
                ]}
                form={form}
                setForm={setForm}
                errors={showErrors}
              />
            </div>
            <div className="MainInfo-Params">
              {form ? (
                <ProductAssignment
                  value={form.assignment.values}
                  syncForm={(newForm) =>
                    syncForm({
                      propIdentifier: 'assignment',
                      form,
                      setForm,
                      newForm,
                    })
                  }
                />
              ) : null}

              <FormRender
                sections={[{ fields: ['params'] }]}
                form={form}
                setForm={setForm}
                errors={showErrors}
              />
            </div>
          </div>
          <h4>Додаткові опції</h4>
          <div className="ProductsManager-OptionsContainer">
            <FormRender
              sections={[{ fields: ['options'] }]}
              form={form}
              setForm={setForm}
              errors={showErrors}
            />
          </div>
          {form ? (
            <ProductAvaliabilityManager ref={avManagerRef} form={form} />
          ) : null}
        </div>
      </PageContainer>
    </>
  )
}

export default withRouter(ProductsManager)
