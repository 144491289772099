import React from 'react'
import './OrderDetails.sass'
import { find, isEmpty } from 'lodash'
import Button from '../../../../../ui/Button/Button'
import CartItemValued from './CartItemValued/CartItemValued'
import toggleSpoiler from '../../../../../utils/toggleSpoiler'
import getFormattedValue from './getFormattedValue'

function OrderDetails({ location, dates, sameDay, data = null, cart, user }) {
  console.log(
    '🚀 ~ file: OrderDetails.jsx ~ line 10 ~ OrderDetails ~ user',
    user
  )
  return (
    <div className="OrderDetails">
      <Button
        theme="internal-link"
        title="Переглянути деталі замовлення"
        icon="angle-down"
        iconPosition="right"
        iconWeight="regular"
        size={32}
        className="DetailsButton"
        onClick={toggleSpoiler}
      />
      <div className="OrderDetails-Body">
        <div className="Info-Header">
          <InfoBlock
            title="Місто або курорт"
            content={<p className="Body-Regular-2">{location.title}</p>}
          />
          <InfoBlock
            title="Термін прокату"
            content={
              <>
                <p className="TextWithButton Body-Regular-2">
                  {getFormattedValue({
                    start: dates.start,
                    end: dates.end,
                    sameDay,
                  })}
                </p>
                {sameDay && (
                  <p className="Caption-Regular">
                    Заберу після 15:00 (-1 день прокату)
                  </p>
                )}
              </>
            }
          />
          {!isEmpty(data) && (
            <InfoBlock
              title="Пункт прокату"
              content={
                <>
                  <p className="Body-Regular-2">{data.shop.title}</p>
                  <p className="Caption-Regular">
                    {location.title},{data.spot.spotAddress}
                  </p>
                </>
              }
            />
          )}
          <InfoBlock
            title="Клієнт"
            content={
              <>
                <p className="Body-Regular-2">
                  {user.name} {user.lastName}
                </p>
                <p className="Caption-Regular">+{user.phone}</p>
              </>
            }
          />
        </div>
        <div className="OrderDetails-Products">
          {!isEmpty(data) && !isEmpty(cart.products)
            ? cart.products.map((p) => {
                const productsByQuantity = []
                for (let index = 0; index < p.quantity; index++) {
                  productsByQuantity.push(
                    <CartItemValued
                      {...p}
                      productData={find(data.products, [
                        'productId',
                        p.productId,
                      ])}
                      productPrice={
                        cart.pricesForProducts[p.productId] /
                        p.quantity /
                        cart.products.filter(
                          (pr) => pr.productId === p.productId
                        ).length
                      }
                      optionsValues={p.optionsValues[index] || {}}
                    />
                  )
                }
                return productsByQuantity
              })
            : null}
          <h3 className="Totals">
            <span>Всього:</span>
            <span>{cart.price} ₴</span>
          </h3>
        </div>
      </div>
    </div>
  )
}

function InfoBlock({ title, content }) {
  return (
    <div className="InfoBlock">
      <p className="Body-Bold-2">{title}</p>
      {content}
    </div>
  )
}

export default OrderDetails
