import React from 'react'
import Icon from '../../Icon/Icon'
import './Checkbox.sass'

function Checkbox({ value = 'off', label, onClick }) {
  let settings = {
    icon: 'minus',
    className: 'Checkbox_theme_indeterminated',
  }

  switch (value) {
    case 'on':
      settings = {
        icon: 'check',
        className: 'Checkbox_theme_on',
      }
      break
    case 'off':
      settings = {
        icon: '',
        className: 'Checkbox_theme_off',
      }
      break
    case 'indeterminated':
      settings = {
        icon: 'minus',
        className: 'Checkbox_theme_indeterminated',
      }
      break
    default:
      settings = {
        icon: '',
        className: 'Checkbox_theme_off',
      }
      break
  }
  return (
    <div className="Checkbox-Container" onClick={onClick}>
      <div className={['Checkbox', settings.className].join(' ')}>
        {settings.icon && <Icon name={settings.icon} weight="regular" />}
      </div>
      {label && <span className="Label">{label}</span>}
    </div>
  )
}

export default Checkbox
