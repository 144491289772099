import React, { useState } from 'react'
import Icon from '../Icon/Icon'
import './NotificationBlock.sass'

export const useNotificationBlock = ({
  show = false,
  theme,
  title,
  content,
} = {}) => {
  const [settings, setSettings] = useState({
    show,
    theme,
    title,
    content,
  })

  return [settings, setSettings]
}

export default function NotificationBlock({ theme = 'info', title, content }) {
  let settings = {}
  switch (theme) {
    case 'info':
      settings = {
        icon: 'info',
        className: 'NotificationBlock_theme_info',
      }
      break
    case 'success':
      settings = {
        icon: 'check',
        className: 'NotificationBlock_theme_success',
      }
      break
    case 'error':
      settings = {
        icon: 'info',
        className: 'NotificationBlock_theme_error',
      }
      break

    default:
      break
  }
  return (
    <div className={['NotificationBlock', settings.className].join(' ')}>
      <div className="Icon-Container">
        <Icon name={settings.icon} weight="solid" />
      </div>
      <div className="Content">
        {title && <h4 className="Title">{title}</h4>}
        <p className="Body-Regular-2">{content}</p>
      </div>
    </div>
  )
}
