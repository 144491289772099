import React from 'react'
import { Img as Image } from 'react-image'
import Skeleton from '../Skeleton/Skeleton'

function Img({ src = '', className }) {
  return (
    <Image
      key={src}
      className={className}
      src={src}
      loader={<Skeleton className={className} />}
    />
  )
}
export default Img
