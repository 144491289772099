import React, { useState } from 'react'
import './CancelOrderPopUp.sass'
import PopUp from '../../../../../../ui/PopUp/PopUp'
import Button from '../../../../../../ui/Button/Button'
import NotificationBlock, {
  useNotificationBlock,
} from '../../../../../../ui/NotificationBlock/NotificationBlock'
import updateDoc from '../../../../../../utils/db/updateDoc'

function CancelOrderPopUp({ docId, number, close }) {
  const [isLoading, setIsLoading] = useState(false)

  const onCancel = () => {
    setIsLoading(true)
    updateDoc({
      path: 'orders',
      docId,
      data: { status: 'e2QJKCyNal7CmLhFVOsZ', updated: new Date() },
    }).then(() => close())
  }

  return (
    <PopUp
      show
      title="Скасування замовлення"
      className="CancelOrderPopUp"
      close={close}
    >
      <div className="CancelOrderPopUp-Container">
        <p className="Text Body-Regular-2">
          Ви справді бажаєте скасувати замовлення{' '}
          <span className="Body-Bold-2">№{number}</span>
        </p>
        <div className="Buttons">
          <Button
            title="Скасувати замовлення"
            size={40}
            onClick={onCancel}
            isLoading={isLoading}
          />
          <Button
            title="Відмінити"
            size={40}
            theme="internal-link"
            onClick={close}
          />
        </div>
      </div>
    </PopUp>
  )
}

export default CancelOrderPopUp
