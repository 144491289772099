import React, { useState, useEffect } from 'react'
import './ParamsList.sass'
import { withRouter } from 'react-router-dom'
import { find, sortBy } from 'lodash'
import { db } from '../../../../../../config/firebase'
import Button from '../../../../../../ui/Button/Button'
import Spinner from '../../../../../../ui/Spinner/Spinner'
import PageTitle from '../../../../../../ui/Page/PageTitle/PageTitle'
import PageContainer from '../../../../../../ui/Page/PageContainer/PageContainer'
import Table from '../../../../../../ui/Table/Table'
import ParamsManager from '../ParamsManager/ParamsManager'
import { optionTypes } from '../../functions/paramObject'

function ParamsList({ newParam, ...router }) {
  const [params, setParams] = useState(null)
  const { paramId } = router.match.params

  useEffect(() => {
    db.collection('config/products/productParams').onSnapshot((resp) => {
      const pArray = []
      resp.forEach((p) => {
        const paramData = p.data()
        const choosedOption = find(optionTypes, ['value', paramData.type])
        paramData.type = choosedOption.label
        pArray.push({ paramId: p.id, ...paramData })
      })
      sortBy(pArray, ['label'])
      setParams(pArray)
    })
  }, [])

  return !params ? (
    <Spinner type="module" />
  ) : (
    <div className="ParamsList">
      {(newParam || paramId) && <ParamsManager paramId={paramId} />}
      <PageTitle
        title="Параметри товарів"
        headerContent={
          <Button
            type="navlink"
            path="/settings/params/add"
            theme="primary"
            size={32}
            icon="plus"
            iconPosition="left"
            title="Створити новий параметр"
          />
        }
      />
      <PageContainer>
        <Table
          editOnDoubleClick
          tableHead={[['Лейбл'], ['Назва'], ['Тип'], ['', '18']]}
          tableBody={
            params
              ? params.map((p) => [
                  { id: p.paramId },
                  p.label,
                  p.title,
                  p.type,
                  'options',
                ])
              : []
          }
          options={[
            {
              return: 'docId',
              type: 'edit',
              icon: 'pencil-alt',
              label: 'Редаг.',
              clicked: (id) =>
                router.history.push(`/settings/params/edit/${id}`),
            },
            {
              return: 'docId',
              type: 'delete',
              icon: 'trash-alt',
              label: 'Видалити',
              clicked: (id) =>
                db.collection('config/products/productParams').doc(id).delete(),
            },
          ]}
        />
      </PageContainer>
    </div>
  )
}

export default withRouter(ParamsList)
