import React from 'react'
import './Table.sass'
import SceneTable from './SceneTable/SceneTable'

function Table({
  editOnDoubleClick,
  headerChildren,
  tableHead,
  tableBody,
  options,
}) {
  return (
    <div className="Table">
      <div className="Table-Header">{headerChildren}</div>
      <div className="Table-Content">
        <SceneTable
          editOnDoubleClick={editOnDoubleClick}
          header={tableHead}
          body={tableBody}
          options={options}
        />
      </div>
    </div>
  )
}

export default Table
