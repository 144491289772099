import React, { useState, useEffect } from 'react'
import { createForm } from '../../utils/forms/createForm'
import FieldRender from '../../utils/forms/render/FieldRender'
import Icon from '../Icon/Icon'
import './ProductAvatar.sass'
import getFieldRenderObject from '../../utils/forms/render/getFieldRenderObject'
import fileToBas64 from '../../utils/files/fileToBase64'
import Button from '../Button/Button'

function ProductAvatar({ initialData, src, syncForm = () => null }) {
  const [form, setForm] = useState(
    createForm({
      formPattern: {
        avatar: {
          field: {
            fieldId: 'avatar',
            fieldType: 'inputFile',
            inputType: 'file',
            value: initialData,
          },
          render: getFieldRenderObject(),
        },
      },
    })
  )

  const onFileAdded = (newForm, fieldId) => {
    fileToBas64(newForm[fieldId].value.file).then((converted) => {
      newForm[fieldId].value.fileUrl = converted
      setForm(newForm)
    })
  }

  const onImgDelete = () => {
    setForm(
      createForm({
        formPattern: {
          avatar: {
            field: {
              fieldId: 'avatar',
              fieldType: 'inputFile',
              inputType: 'file',
              value: {},
            },
            render: getFieldRenderObject(),
          },
        },
      })
    )
  }

  useEffect(() => {
    if (form) {
      syncForm(form)
    }
  }, [form])

  return src ? (
    <div className="ProductAvatar ProductAvatar-Img">
      <img src={src} />
      <div className="ProductAvatar-DeleteScene">
        <Button icon="trash" size={32} onClick={onImgDelete} />
      </div>
    </div>
  ) : (
    <div className="ProductAvatar ProductAvatar-Upload">
      <Icon name="box" />
      <FieldRender
        key="avatar"
        field={form.avatar}
        form={form}
        setForm={(newForm) => onFileAdded(newForm, 'avatar')}
      />
    </div>
  )
}

export default ProductAvatar
