import React, { useState, useEffect } from 'react'
import './ParamsManager.sass'
import { withRouter } from 'react-router-dom'
import PopUp from '../../../../../../ui/PopUp/PopUp'
import getDoc from '../../../../../../utils/db/getDoc'
import { createForm } from '../../../../../../utils/forms/createForm'
import ParamObject from '../../functions/paramObject'
import FormRender from '../../../../../../utils/forms/render/FormRender'
import Button from '../../../../../../ui/Button/Button'
import isFormValid from '../../../../../../utils/forms/validation/isFormValid'
import getFormValues from '../../../../../../utils/forms/getFormValues'
import updateDoc from '../../../../../../utils/db/updateDoc'
import addDoc from '../../../../../../utils/db/addDoc'

function ParamsManager({ paramId, ...router }) {
  const [form, setForm] = useState(null)
  const [showErrors, setShowErrors] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (paramId) {
      getDoc({
        path: 'config/products/productParams',
        docId: paramId,
        docIdName: 'paramId',
      }).then((formData) =>
        setForm(createForm({ formPattern: new ParamObject(), formData }))
      )
    } else {
      setForm(createForm({ formPattern: new ParamObject() }))
    }
  }, [])

  const close = () => {
    router.history.push('/settings/params')
  }

  const onSubmit = () => {
    if (!isFormValid({ form })) {
      return setShowErrors(true)
    }

    setShowErrors(false)
    setIsLoading(true)

    const values = getFormValues({ form })

    const opRef = paramId
      ? updateDoc({
          path: 'config/products/productParams',
          docId: paramId,
          data: { ...values, updated: new Date() },
        })
      : addDoc({
          path: 'config/products/productParams',
          data: values,
        })

    opRef
      .then((docId) => router.history.push(`/settings/params/edit/${docId}`))
      .catch((error) => {
        console.log(
          '🚀 ~ file: ManageProjectPage.jsx ~ line 79 ~ onSubmit ~ error',
          error
        )
      })
  }

  return (
    <PopUp
      title={`${paramId ? 'Редагувати' : 'Створити новий'} параметр`}
      show
      close={close}
      className="ParamsManager-PopUp"
    >
      <div className="ParamsManager-Container">
        <FormRender
          sections={[{ fields: ['label', 'title', 'type', 'options'] }]}
          form={form}
          setForm={setForm}
          errors={showErrors}
        />
        <div className="ParamsManager-Buttons">
          <Button
            title="Зберегти"
            size={40}
            theme="primary"
            onClick={onSubmit}
            isLoading={isLoading}
          />
          <Button
            title="Відмінити"
            size={40}
            theme="secondary"
            onClick={close}
          />
        </div>
      </div>
    </PopUp>
  )
}

export default withRouter(ParamsManager)
