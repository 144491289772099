import React, { useState, useEffect } from 'react'
import './OrdersManager.sass'
import { withRouter } from 'react-router-dom'
import { db } from '../../../../config/firebase'
import Spinner from '../../../../ui/Spinner/Spinner'
import PageTitle from '../../../../ui/Page/PageTitle/PageTitle'
import PageContainer from '../../../../ui/Page/PageContainer/PageContainer'
import OrderData from './OrderData/OrderData'

function OrdersManager({ ...router }) {
  const [order, setOrder] = useState(null)

  useEffect(() => {
    const docId = router.match.params.orderId
    db.collection('orders')
      .doc(docId)
      .onSnapshot((data) => {
        if (!data.exists) return router.history.push('/orders')

        const orderData = { docId: data.id, ...data.data() }

        setOrder(orderData)
        // return promiseAllValues({
        //   order: fetchOrder(orderData),
        //   product: fetchProductData(orderData.productId).then(
        //     (productData) => productData
        //   ),
        // }).then((results) => {
        //   setOrder({ ...results.order, product: results.product })
        // })
      })
  }, [])

  // const getPledgeTypes = (pledgeType) => {
  //   const types = [
  //     { label: 'Завдаток готівкою на місці', value: 'cash' },
  //     { label: 'Завдаток паспорт на місці', value: 'passport' },
  //   ]
  //   return find(types, ['value', pledgeType]).label
  // }

  return order ? (
    <>
      <PageTitle
        title={`Замовлення #${order.orderId}`}
        // headerContent={
        //   <div className="PageTitle-HeaderContent-Controlls">
        //     <Button theme="bounded" size="36">
        //       <Icon name="trash-alt" className="ButtonChild" />
        //       <span className="ButtonChild">Запит на відміну замовлення</span>
        //     </Button>
        //   </div>
        // }
      />
      <PageContainer>
        <OrderData />
      </PageContainer>
    </>
  ) : (
    <Spinner type="module" />
  )
}

export default withRouter(OrdersManager)
