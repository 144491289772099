import getFieldRenderObject from '../../../../../utils/forms/render/getFieldRenderObject'

export const optionTypes = [
  { label: 'Строка', value: 'string' },
  { label: 'Число', value: 'number' },
  { label: 'Чекбокс', value: 'checkbox' },
  { label: 'Вибір опцій', value: 'options' },
]

export default class ParamObject {
  constructor({ disabledFields = [] } = {}) {
    this.label = {
      field: {
        fieldId: 'label',
        fieldType: 'input',
        inputType: 'text',
        label: 'Лейбл',
        placeholder: 'Вкажіть лейбл параметра',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.title = {
      field: {
        fieldId: 'title',
        fieldType: 'input',
        inputType: 'text',
        label: 'Назва',
        placeholder: 'Вкажіть назву параметра',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.type = {
      field: {
        fieldId: 'type',
        fieldType: 'select',
        label: 'Тип',
        placeholder: 'Виберіть тип параметра',
        required: true,
        getOptions: optionTypes,
      },
      render: getFieldRenderObject(),
    }
    this.options = {
      field: {
        fieldId: 'options',
        fieldType: 'fieldset',
        fields: [
          {
            field: {
              fieldId: 'label',
              fieldType: 'input',
              inputType: 'text',
              label: 'Назва опції',
              placeholder: 'Вкажіть назву опції',
            },
            render: getFieldRenderObject(),
          },
          {
            field: {
              fieldId: 'value',
              fieldType: 'input',
              inputType: 'text',
              label: 'Значення опції',
              placeholder: 'Вкажіть значення опції',
            },
            render: getFieldRenderObject(),
          },
        ],
        systemField: false,
      },
      render: getFieldRenderObject({
        byDefault: false,
        ifFieldId: 'type',
        value: 'options',
      }),
    }
    this.created = {
      field: {
        fieldId: 'created',
        value: new Date(),
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.updated = {
      field: {
        fieldId: 'updated',
        value: new Date(),
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.version = {
      field: {
        fieldId: 'version',
        value: '1.0',
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
  }
}
