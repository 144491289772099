import React, { useState, useEffect } from 'react'
import './Cities.sass'
import { Route, withRouter } from 'react-router-dom'
import { db } from '@bit/ollapp.components.firebase'
import { orderBy } from 'lodash'
import Button from '../../../../ui/Button/Button'
import PageTitle from '../../../../ui/Page/PageTitle/PageTitle'
import PageContainer from '../../../../ui/Page/PageContainer/PageContainer'
import Table from '../../../../ui/Table/Table'
import CityManager from './CityManager/CityManager'

function Cities({ ...router }) {
  const [cities, setCities] = useState(null)

  useEffect(() => {
    db.collection('cities').onSnapshot((snap) => {
      const arr = []
      snap.forEach((d) => arr.push({ cityId: d.id, ...d.data() }))
      setCities(orderBy(arr, ['title'], ['asc']))
    })
  }, [])

  const deleteCity = (cityId) => {
    db.collection('cities').doc(cityId).delete()
  }

  return (
    <>
      <Route
        path={['/settings/cities/add', '/settings/cities/:id/edit']}
        exact
        render={() => <CityManager />}
      />
      <div className="Slider">
        <PageTitle
          title="Міста"
          headerContent={
            <Button
              size={32}
              title="Додати нове місто"
              onClick={() => router.history.push('/settings/cities/add')}
            />
          }
        />
        <PageContainer>
          <Table
            tableHead={[['Місто'], ['', '48']]}
            tableBody={
              cities
                ? cities.map((c, i) => [{ id: c.cityId }, c.title, 'options'])
                : []
            }
            options={[
              {
                return: 'docId',
                type: 'edit',
                icon: 'pencil-alt',
                label: 'Редаг.',
                clicked: (cityId) =>
                  router.history.push(`/settings/cities/${cityId}/edit`),
              },
              {
                return: 'docId',
                icon: 'trash-alt',
                label: 'Видалити',
                clicked: deleteCity,
              },
            ]}
          />
        </PageContainer>
      </div>
    </>
  )
}

export default withRouter(Cities)
