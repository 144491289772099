import React, { useState, useEffect } from 'react'
import './ProductAssignment.sass'
import { cloneDeep } from 'lodash'
import Checkbox from '../../../../../ui/Field/Checkbox/Checkbox'

export const options = [
  { label: 'Чол', value: 'male' },
  { label: 'Жін', value: 'female' },
  { label: 'Дит', value: 'kid' },
]

function ProductAssignment({ value, syncForm }) {
  const [form, setForm] = useState(value)

  useEffect(() => {
    syncForm(form)
  }, [form])

  const onAssignmentClick = (option) => {
    const formClone = cloneDeep(form)
    if (formClone.includes(option)) {
      setForm(formClone.filter((v) => v !== option))
    } else {
      formClone.push(option)
      setForm(formClone)
    }
  }

  return (
    <div className="ProductAssignment">
      {options.map((o) => (
        <Checkbox
          {...o}
          value={form.includes(o.value) ? 'on' : 'off'}
          onClick={() => onAssignmentClick(o.value)}
        />
      ))}
    </div>
  )
}

export default ProductAssignment
