import React, { useState, useEffect } from 'react'
import './ProductsTable.sass'
import { withRouter } from 'react-router-dom'
import { find, isEmpty } from 'lodash'
import Button from '../../../ui/Button/Button'
import Spinner from '../../../ui/Spinner/Spinner'
import PageTitle from '../../../ui/Page/PageTitle/PageTitle'
import PageContainer from '../../../ui/Page/PageContainer/PageContainer'
import Table from '../../../ui/Table/Table'
import getCollection from '../../../utils/db/getCollection'
import promiseAllValues from '../../../utils/promiseAllValues'
import { options as assignmentOptions } from '../ProductsManager/components/ProductAssignment/ProductAssignment'

function ProductsTable({ ...router }) {
  const [products, setProducts] = useState(null)

  useEffect(() => {
    promiseAllValues({
      products: getCollection({ path: 'products', docIdName: 'productId' }),
      categories: getCollection({
        path: 'config/products/categories',
        docIdName: 'categoryId',
      }),
    }).then((result) => {
      setProducts(
        result.products.map((p) => {
          const category = find(result.categories, ['categoryId', p.category])
            ? find(result.categories, ['categoryId', p.category]).title
            : 'deleted category'
          const subCategory = find(result.categories, [
            'categoryId',
            p.subCategory,
          ])
            ? find(result.categories, ['categoryId', p.subCategory]).title
            : 'deleted category'
          const assignment = !isEmpty(p.assignment)
            ? p.assignment
                .map((a) => find(assignmentOptions, ['value', a]).label)
                .join(', ')
            : '-'
          p.category = category
          p.subCategory = subCategory
          p.assignment = assignment
          return p
        })
      )
    })
  }, [])

  return !products ? (
    <Spinner type="module" />
  ) : (
    <>
      <PageTitle
        title="Товари"
        counter={products.length}
        headerContent={
          <Button
            type="navlink"
            path="/products/add"
            theme="primary"
            size={32}
            icon="plus"
            iconPosition="left"
            title="Додати новий товар"
          />
        }
      />
      <PageContainer>
        <Table
          editOnDoubleClick
          tableHead={[
            ['Назва'],
            ['Категорія'],
            ['Підкатегорія'],
            ['Стать'],
            ['', '18'],
          ]}
          tableBody={products.map((p) => [
            { id: p.productId },
            <span>{p.frontTitle}</span>,
            <span>{p.category}</span>,
            <span>{p.subCategory}</span>,
            <span>{p.assignment}</span>,
            'options',
          ])}
          options={[
            {
              return: 'docId',
              type: 'edit',
              icon: 'pencil-alt',
              label: 'Редаг.',
              clicked: (productId) =>
                router.history.push(`/products/edit/${productId}`),
            },
          ]}
        />
      </PageContainer>
    </>
  )
}

export default withRouter(ProductsTable)
