import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/functions'

const firebaseConfig = {
  apiKey: 'AIzaSyA0BDTlJTQRjmEpEfGr9uvSnAOt-qF_rSs',
  authDomain: 'ollapp-io.firebaseapp.com',
  databaseURL: 'https://ollapp-io.firebaseio.com',
  projectId: 'ollapp-io',
  storageBucket: 'ollapp-io.appspot.com',
  messagingSenderId: '587497611543',
  appId: '1:587497611543:web:c447480f79118477d2d752',
  measurementId: 'G-4VX1V7EYE8',
}

firebase.initializeApp(firebaseConfig)

const firebaseSideEffect = firebase.initializeApp(
  firebaseConfig,
  'Side Effect instance'
)

const db = firebase.firestore()
const storageRef = firebase.storage().ref()
const { storage } = firebase
const functions = firebase.app().functions('europe-west3')

export { db, storage, storageRef, firebase, firebaseSideEffect, functions }
