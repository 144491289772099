import React, { useState, useEffect } from 'react'
import { db } from '../../../../../config/firebase'
import './OrderData.sass'
import { withRouter } from 'react-router-dom'
import { find, isEmpty } from 'lodash'
import Button from '../../../../../ui/Button/Button'
import OrderDetails from '../OrderDetails/OrderDetails'
import getDoc from '../../../../../utils/db/getDoc'
import Spinner from '../../../../../ui/Spinner/Spinner'
import promiseAllValues from '../../../../../utils/promiseAllValues'
import getCollection from '../../../../../utils/db/getCollection'
import CancelOrderPopUp from './CancelOrderPopUp/CancelOrderPopUp'
import ArchiveOrderPopUp from './ArchiveOrderPopUp/ArchiveOrderPopUp'

function OrderData({ ...router }) {
  const [orderData, setOrderData] = useState(null)
  const [showCancel, setShowCancel] = useState(false)
  const [showArchive, setShowArchive] = useState(false)

  useEffect(() => {
    db.collection('orders')
      .doc(router.match.params.orderId)
      .onSnapshot((snap) => {
        const order = { docId: snap.id, ...snap.data() }
        promiseAllValues({
          shop: getDoc({
            path: 'shops',
            docId: order.pickupShop,
            docIdName: 'docId',
          }),
          cities: getCollection({ path: 'cities', docIdName: 'cityId' }),
          products: getCollection({ path: 'products', docIdName: 'productId' }),
          statuses: getCollection({
            path: 'config/orders/statuses',
            docIdName: 'statusId',
          }),
          users: getCollection({ path: 'users', docIdName: 'userId' }),
        }).then((result) => {
          const spot = find(result.shop.spots, ['spotId', order.pickupSpot])
          const city = find(result.cities, ['cityId', spot.spotCity])
          const status = find(result.statuses, ['statusId', order.status])
          const user = find(result.users, ['userId', order.uid])

          const location = find(result.cities, ['cityId', spot.spotCity])
          setOrderData({
            docId: order.docId,
            location,
            cart: order,
            status,
            dates: {
              start: order.dates.start.seconds * 1000,
              end: order.dates.end.seconds * 1000,
            },
            sameDay: order.sameDay,
            city,
            data: {
              shop: result.shop,
              spot,
              products: result.products,
            },
            user,
          })
        })
      })
  }, [])

  return (
    <div className="OrderData">
      <Button
        theme="internal-link"
        title="Повернутися"
        icon="angle-left"
        iconPosition="left"
        iconWeight="regular"
        type="navlink"
        path="/orders"
        size={32}
      />
      {!isEmpty(orderData) ? (
        <>
          {showCancel && (
            <CancelOrderPopUp
              docId={orderData.docId}
              number={orderData.cart.orderId}
              close={() => setShowCancel(false)}
            />
          )}
          {showArchive && (
            <ArchiveOrderPopUp
              docId={orderData.docId}
              number={orderData.cart.orderId}
              close={() => setShowArchive(false)}
            />
          )}
          <h2 className="OrderData-Title">
            {orderData.city.title}{' '}
            <div
              className="OrderStatus Body-Medium-2"
              style={{
                background: orderData.status.background,
                color: orderData.status.color,
              }}
            >
              {orderData.status.title}
            </div>
          </h2>
          <p className="Caption-Regular OrderNum">#{orderData.cart.orderId}</p>
          <OrderDetails {...orderData} />
          <div className="OrderData-Buttons">
            {orderData.status.statusId !== 'e2QJKCyNal7CmLhFVOsZ' && (
              <Button
                title="Скасувати замовлення"
                theme="secondary"
                size={40}
                onClick={() => setShowCancel(true)}
              />
            )}
            {orderData.status.statusId !== 'WCZpA64uC6lSVwnP24xq' && (
              <Button
                title="Виконати замовлення"
                size={40}
                onClick={() => setShowArchive(true)}
              />
            )}
          </div>
        </>
      ) : (
        <div className="Spinner-Container">
          <Spinner type="button" theme="gray" />
        </div>
      )}
    </div>
  )
}

export default withRouter(OrderData)
