import React, { useRef } from 'react'
import './OptionsMenu.sass'

import DropdownBox from '../DropdownBox/DropdownBox'
import Icon from '../Icon/Icon'

const OptionsMenu = React.forwardRef(({ options, styles }, ref) => (
  <DropdownBox ref={ref} styles={styles}>
    <ul className="OptionsList">
      {options.map((option) => (
        <li className="OptionsList-Element" onClick={() => option.clicked()}>
          <div className="OptionsList-ElementIcon">
            <Icon name={option.icon} />
          </div>
          <span>{option.label}</span>
        </li>
      ))}
    </ul>
  </DropdownBox>
))

export default OptionsMenu
