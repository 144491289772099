import React from 'react'
import './Partners.sass'
import { Route } from 'react-router-dom'
import PartnersList from './components/ShopsList/ShopsList'
import PartnerManager from './components/PartnerManager/PartnerManager'
// import ShopManager from './components/ShopManager/ShopManager'
// import SpotsList from './components/SpotsList/SpotsList'
// import EmployeeManager from './components/EmployeeManager/EmployeeManager'

function Partners() {
  return (
    <section className="Partners">
      <Route path="/partners" exact component={PartnersList} />
      {/* <Route path="/shops/shop/:shopId" exact render={() => <SpotsList />} /> */}
      {/* <Route
        path="/shops/shop/:shopId/spot/:spotId/add"
        exact
        render={() => <EmployeeManager />}
      /> */}
      {/* <Route
        path="/shops/shop/:shopId/spot/:spotId/edit/:employeeId"
        exact
        render={() => <EmployeeManager editMode />}
      /> */}
      <Route path="/partners/add" exact component={PartnerManager} />
      <Route
        path="/partners/edit/:partnerId"
        component={PartnerManager}
        exact
      />
    </section>
  )
}

export default Partners
