import React from 'react'
import './Auth.sass'
import Logo from '@bit/ollapp.components.logo'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import SignIn from './SignIn'

function Auth({ user }) {
  return !user ? (
    <div className="Auth-Container">
      <div className="Auth">
        <Logo theme="primary" direction="horizontal" navLink="/" />
        <SignIn />
      </div>
    </div>
  ) : (
    <Route path="/auth">
      <Redirect to="/" />
    </Route>
  )
}

const mapStateToProps = (state) => ({ user: state.auth.user })

export default connect(mapStateToProps)(Auth)
