import React, { useState, useEffect } from 'react'
import './PartnerManager.sass'
import { isEmpty, isArray, cloneDeep } from 'lodash/isEmpty'
import { withRouter } from 'react-router-dom'
import uniqid from 'uniqid'
import PageTitle from '../../../../ui/Page/PageTitle/PageTitle'
import PageContainer from '../../../../ui/Page/PageContainer/PageContainer'
import Button from '../../../../ui/Button/Button'
import FormRender from '../../../../utils/forms/render/FormRender'
import getDoc from '../../../../utils/db/getDoc'
import { createForm } from '../../../../utils/forms/createForm'
import PartnerObject from './partnerObject'
import useSystemFieldsMethods from '../../../../utils/forms/useSystemFieldsMethods'
import NotificationBlock, {
  useNotificationBlock,
} from '../../../../ui/NotificationBlock/NotificationBlock'
import getFormValues from '../../../../utils/forms/getFormValues'
import updateDoc from '../../../../utils/db/updateDoc'
import addDoc from '../../../../utils/db/addDoc'

function PartnerManager({ ...router }) {
  const { partnerId } = router.match.params

  const [form, setForm] = useState(null)
  console.log(
    '🚀 ~ file: PartnerManager.jsx ~ line 25 ~ PartnerManager ~ form',
    form
  )
  const methods = useSystemFieldsMethods({ form })
  const [showErrors, setShowErrors] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [nBlockSettings, setNBlockSettings] = useNotificationBlock()

  useEffect(() => {
    if (partnerId) {
      getDoc({
        path: 'shops',
        docId: partnerId,
        docIdName: 'partnerId',
      }).then((data) => {
        setForm(
          createForm({
            formPattern: new PartnerObject(),
            formData: data,
            methods,
          })
        )
      })
    } else {
      setForm(
        createForm({
          formPattern: new PartnerObject(),
          methods,
        })
      )
    }
  }, [])

  const onSubmit = () => {
    const values = getFormValues({ form })
    setIsLoading(true)

    const opRef = partnerId
      ? updateDoc({
          path: 'shops',
          docId: partnerId,
          data: { ...values, updated: new Date() },
        })
      : addDoc({
          path: 'shops',
          data: values,
        })

    opRef
      .then((docId) => {
        router.history.push(`/partners/edit/${docId}`)
        setIsLoading(false)
        setNBlockSettings({
          show: true,
          theme: 'success',
          content: 'Партнер успішно збережений',
        })
        // setTimeout(() => setNBlockSettings({ show: false }), 3000)
      })
      .catch((error) => {
        console.log(
          '🚀 ~ file: ManageProjectPage.jsx ~ line 79 ~ onSubmit ~ error',
          error
        )
      })
  }

  return (
    <>
      <PageTitle
        title={partnerId ? 'Редагувати партнера' : 'Додати нового партнера'}
        headerContent={
          <div className="PageTitle-HeaderContent-Controlls">
            <Button
              title="Назад"
              theme="internal-link"
              onClick={() => router.history.push('/partners')}
              size={40}
            />
            <Button
              title="Видалити"
              theme="secondary"
              onClick={() => null}
              size={40}
            />
            <Button
              title="Зберегти"
              theme="primary"
              onClick={onSubmit}
              size={40}
              isLoading={isLoading}
            />
          </div>
        }
      />
      <PageContainer>
        <div className="PartnerManager">
          {nBlockSettings.show && <NotificationBlock {...nBlockSettings} />}
          <FormRender
            sections={[{ fields: ['title', 'percent', 'spots'] }]}
            form={form}
            setForm={setForm}
            errors={showErrors}
          />
        </div>
      </PageContainer>
    </>
  )
}

export default withRouter(PartnerManager)
