import React from 'react'
import ReactDOM from 'react-dom'
import OllApp from './OllApp'
import './global.sass'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import * as serviceWorker from './serviceWorker'
import rootSaga from './store/sagas/rootSaga'
import rootReducer from './store/reducers/rootReducer'

const sagaMiddleware = createSagaMiddleware()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
)

sagaMiddleware.run(rootSaga)

ReactDOM.render(
  <Provider store={store}>
    <OllApp />
  </Provider>,
  document.getElementById('ollApp')
)
serviceWorker.unregister()
