import React, { useState, useEffect } from 'react'
import './ShopsList.sass'
import { db } from '@bit/ollapp.components.firebase'
import Spinner from '@bit/ollapp.components.spinner'
import { withRouter } from 'react-router-dom'
import { isEmpty, find, uniq } from 'lodash'
import PageTitle from '../../../../ui/Page/PageTitle/PageTitle'
import PageContainer from '../../../../ui/Page/PageContainer/PageContainer'
import Table from '../../../../ui/Table/Table'
import Button from '../../../../ui/Button/Button'
import getCollection from '../../../../utils/db/getCollection'

function ShopsList({ ...router }) {
  const [shops, setShops] = useState(null)

  useEffect(() => {
    getCollection({ path: 'cities', docIdName: 'cityId' }).then((cities) => {
      db.collection('shops').onSnapshot((resp) => {
        const shopsArray = []
        resp.forEach((s) => {
          shopsArray.push({
            shopId: s.id,
            ...s.data(),
            spots: s.data().spots.map((spot) => {
              spot.spotCity = find(cities, ['cityId', spot.spotCity])
                ? find(cities, ['cityId', spot.spotCity]).title
                : 'undefined city'
              return spot
            }),
          })
        })

        setShops(shopsArray)
      })
    })
  }, [])

  return !shops ? (
    <Spinner type="module" />
  ) : (
    <div className="ShopsList">
      {/* <PageTitle title="Необхідно підтвердити профіль" counter="3" />
      <PageContainer>
        <Table
          tableHead={[
            ['Клієнт'],
            ['Дата реєстрації'],
            ['E-mail'],
            ['Телефон'],
            ['Статус профіля'],
            ['', '18'],
          ]}
          tableBody={[TableRow]}
          options={<OptionsButton />}
        />
      </PageContainer> */}
      <PageTitle
        title="Всі партнери"
        counter={shops.length}
        headerContent={
          <Button
            type="navlink"
            path="/partners/add"
            theme="primary"
            size={32}
            icon="plus"
            iconPosition="left"
            title="Додати нового партнера"
          />
        }
      />
      <PageContainer>
        <Table
          editOnDoubleClick
          tableHead={[['Назва'], ['Локації'], ['Курорти'], ['', '18']]}
          tableBody={
            shops
              ? shops.map((s) => [
                  { id: s.shopId },
                  <span>{s.title}</span>,
                  <span>{s.spots.length}</span>,
                  <span>
                    {uniq(s.spots.map((spot) => spot.spotCity)).join(', ')}
                  </span>,
                  'options',
                ])
              : []
          }
          options={[
            {
              return: 'docId',
              type: 'edit',
              icon: 'pencil-alt',
              label: 'Редакт.',
              clicked: (shopId) =>
                router.history.push(`/partners/edit/${shopId}`),
            },
          ]}
        />
      </PageContainer>
    </div>
  )
}

export default withRouter(ShopsList)
