import ru from 'react-phone-number-input/locale/ru.json'
import sortBy from 'lodash/sortBy'
import { getCountries } from 'libphonenumber-js/max'
import { createSelectbox } from '../../../../utils/forms/createFormElement/createSelectbox'

const createCountrySelect = (country, defaultCountry) => {
  const countries = getCountries()
  const fetchedCountries = countries.map((c) => ({
    label: ru[c],
    value: c,
  }))
  sortBy(fetchedCountries, ['label'])

  return {
    ...createSelectbox({
      fieldId: 'countryCode',
      label: 'Код',
      required: false,
      getOptions: fetchedCountries,
      isSearchable: true,
    }),
    value: country || defaultCountry,
    setPropAsLabel: 'value',
    class: 'CountrySelect',
    ddbStyles: { minWidth: '180px', left: 0 },
  }
}

export default createCountrySelect
