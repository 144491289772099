import { isEmpty, cloneDeep, find, omit, merge } from 'lodash'
import uniqid from 'uniqid'
import uploadFiles from '../../../../utils/storage/uploadFiles'
import updateDoc from '../../../../utils/db/updateDoc'
import { createForm } from '../../../../utils/forms/createForm'
import ProductObject from './productObject'

const uploadPhotos = ({
  values,
  docId,
  form,
  setForm,
  docPath = 'products',
  storagePath = 'products',
  methods,
}) => {
  const photosToUpload = []
  const vls = cloneDeep(values)

  if (vls.avatar.needUpload) {
    photosToUpload.push({ type: 'avatar', value: vls.avatar })
  }

  vls.options.forEach((o, index) => {
    if (o.avatar.needUpload) {
      photosToUpload.push({ type: 'option', pos: index, value: o.avatar })
    }
  })

  if (!isEmpty(photosToUpload)) {
    const path = `${storagePath}/${docId}`

    return uploadFiles({ files: photosToUpload, storagePath: path }).then(
      (uploadedPhotos) => {
        uploadedPhotos.forEach((p) => {
          if (p.type === 'avatar') {
            vls.avatar = merge(omit(p.value, ['file', 'fileUrl']), {
              needUpload: false,
            })
          }
          if (p.type === 'option') {
            vls.options[p.pos].avatar = merge(
              omit(p.value, ['file', 'fileUrl']),
              { needUpload: false }
            )
          }
        })
        return updateDoc({
          path: docPath,
          docId,
          data: vls,
        }).then((thispId) => {
          const formClone = cloneDeep(form)
          formClone.avatar.value.needUpload = false
          delete formClone.avatar.value.file
          formClone.options.values.forEach((o) => {
            const avatar = find(o, ['fieldId', 'avatar'])
            avatar.value.needUpload = false
            delete avatar.value.file
          })
          setForm(formClone)
          return thispId
        })
      }
    )
  }

  vls.options.forEach((o) => {
    if (!o.id) {
      o.id = uniqid('option-')
    }
  })

  return updateDoc({
    path: docPath,
    docId,
    data: {
      avatar: vls.avatar,
      options: vls.options,
    },
  }).then((thispId) => {
    setForm(
      createForm({ formData: vls, formPattern: new ProductObject(), methods })
    )
    return thispId
  })
}

export default uploadPhotos
