import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
// eslint-disable-next-line
import { connect } from 'react-redux'
import PrivateRoute from '@bit/ollapp.components.private-route'
import FontAwesomeLibrary from './config/fontawesome'
import { firebase, db } from './config/firebase'
import Auth from './components/Auth/Auth'
import Layout from './components/Layout/Layout'
import * as actions from './store/actions'

function OllApp(props) {
  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        db.collection('users')
          .doc(user.uid)
          .onSnapshot((userData) => {
            props.authGetState({ uid: user.uid, ...userData.data() })
          })
      } else {
        props.authGetState(null)
      }
    })
  }, [])

  return !props.authIsLoading ? (
    <Router>
      <Switch>
        <Route path="/auth/sign-in" exact component={Auth} />
        <PrivateRoute path="/" component={Layout} redirectTo="signIn" />
      </Switch>
    </Router>
  ) : (
    ''
  )
}

const mapStateToProps = (state) => ({ authIsLoading: state.auth.isLoading })

const mapDispatchToProps = (dispatch) => ({
  authGetState: (payload) => dispatch(actions.authGetState(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(OllApp)
