import React from 'react'
import './GenderIcon.sass'
import Icon from '../../../../../../../ui/Icon/Icon'

function GenderIcon({ type }) {
  let theme = ''
  switch (type) {
    case 'male':
      theme = 'GenderIcon_theme_male'
      break
    case 'female':
      theme = 'GenderIcon_theme_female'
      break
    case 'kid':
      theme = 'GenderIcon_theme_kid'
      break
    default:
      theme = 'GenderIcon_theme_male'
      break
  }

  return (
    <div className={['GenderIcon', theme].join(' ')}>
      <Icon name={type === 'kid' ? 'child' : type} weight="solid" />
    </div>
  )
}

export default GenderIcon
