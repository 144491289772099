const toggleSpoiler = (event) => {
  const spoilerHeader = event.currentTarget
  const spoiler = spoilerHeader.parentElement
  if (spoiler.classList.contains('Spoiler_status_expanded')) {
    spoiler.classList.remove('Spoiler_status_expanded')
  } else {
    spoiler.classList.add('Spoiler_status_expanded')
  }
}

export default toggleSpoiler
