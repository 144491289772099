import React from 'react'
import './Products.sass'
import { Route, Switch } from 'react-router-dom'
// import Product from '@bit/ollapp.components.product'
import ProductsTable from './ProductsTable/ProductsTable'
import ProductsManager from './ProductsManager/ProductsManager'
// import ProductStat from './ProductStat/ProductStat'

function Products() {
  return (
    <section className="Products">
      <Switch>
        <Route path="/products/edit/:productId" component={ProductsManager} />
        <Route path="/products/add" component={ProductsManager} />
        <Route path="/products" component={ProductsTable} />
      </Switch>
    </section>
  )
}

export default Products
