import { takeLatest } from 'redux-saga/effects'
import * as actions from '../../actions/actionTypes'
import getAuthStateSaga from './getAuthStateSaga'
import authSignOutUserSaga from './authSignOutUserSaga'

const authSagas = [
  takeLatest(actions.AUTH_GET_AUTH_STATE, getAuthStateSaga),
  takeLatest(actions.AUTH_SIGN_OUT_USER, authSignOutUserSaga),
]

export default authSagas
