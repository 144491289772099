import uniqid from 'uniqid'
import getFieldRenderObject from '../../../../utils/forms/render/getFieldRenderObject'

export default class PartnerObject {
  constructor({ disabledFields = [] } = {}) {
    this.title = {
      field: {
        fieldId: 'title',
        fieldType: 'input',
        inputType: 'text',
        label: 'Назва партнера',
        placeholder: 'Вкажіть назву партнера',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.percent = {
      field: {
        fieldId: 'percent',
        fieldType: 'input',
        inputType: 'number',
        label: 'Відсоток %',
        placeholder: 'Відсоток винагороди',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.spots = {
      field: {
        fieldId: 'spots',
        fieldType: 'fieldset',
        fields: [
          {
            field: {
              fieldId: 'isActive',
              fieldType: 'checkbox',
              label: 'Активна',
              value: true,
            },
            render: getFieldRenderObject(),
          },
          {
            field: {
              fieldId: 'title',
              fieldType: 'input',
              inputType: 'text',
              label: 'Назва локації',
              placeholder: 'Вкажіть назву локації',
            },
            render: getFieldRenderObject(),
          },
          {
            field: {
              fieldId: 'spotCity',
              fieldType: 'select',
              label: 'Курорт',
              getOptions: 'getCitiesOptions',
            },
            render: getFieldRenderObject(),
          },
          {
            field: {
              fieldId: 'phone',
              fieldType: 'input',
              inputType: 'text',
              label: 'Телефон',
              mask: 'phone',
              placeholder: '+38(0XX) XXX-XX-XX',
            },
            render: getFieldRenderObject(),
          },
          {
            field: {
              fieldId: 'spotAddress',
              fieldType: 'input',
              inputType: 'text',
              label: 'Адреса',
              placeholder: 'Адреса локації',
            },
            render: getFieldRenderObject(),
          },
          {
            field: {
              fieldId: 'spotBusyHours',
              fieldType: 'input',
              inputType: 'text',
              label: 'Графік роботи',
              placeholder: 'Вкажіть графік роботи',
            },
            render: getFieldRenderObject(),
          },
          {
            field: {
              fieldId: 'lat',
              fieldType: 'input',
              inputType: 'text',
              label: 'Координата lat',
              placeholder: 'Lat',
            },
            render: getFieldRenderObject(),
          },
          {
            field: {
              fieldId: 'lng',
              fieldType: 'input',
              inputType: 'text',
              label: 'Координата lng',
              placeholder: 'Lng',
            },
            render: getFieldRenderObject(),
          },
          {
            field: {
              fieldId: 'mapLink',
              fieldType: 'input',
              inputType: 'text',
              label: 'Посилання на карту',
              placeholder: 'Посилання на карту',
            },
            render: getFieldRenderObject(),
          },
          {
            field: {
              fieldId: 'spotId',
              fieldType: 'input',
              inputType: 'text',
              label: 'ID',
              value: uniqid('spot-'),
            },
            render: getFieldRenderObject({ isSystem: true }),
          },
        ],
        systemField: false,
      },
      render: getFieldRenderObject(),
    }

    this.created = {
      field: {
        fieldId: 'created',
        value: new Date(),
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.updated = {
      field: {
        fieldId: 'updated',
        value: new Date(),
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.version = {
      field: {
        fieldId: 'version',
        value: '1.0',
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
  }
}
