import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Icon = ({ name, weight, className, onClick, size, style }) => {
  let fStyle
  switch (weight) {
    case 'light':
      fStyle = 'fal'
      break
    case 'solid':
      fStyle = 'fas'
      break
    case 'brand':
      fStyle = 'fab'
      break
    case 'dualtone':
      fStyle = 'fad'
      break
    default:
      fStyle = 'far'
  }

  const classes = ['Icon']
  className && classes.push(className)

  return (
    <FontAwesomeIcon
      onClick={onClick}
      icon={[fStyle, name]}
      className={classes.join(' ')}
      style={size ? { fontSize: `${size}px` } : style}
    />
  )
}

export default Icon
