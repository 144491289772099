import * as actionTypes from './actionTypes'

export const authGetState = (payload) => ({
  type: actionTypes.AUTH_GET_AUTH_STATE,
  payload,
})

export const authSendUserDataToReducer = (payload) => ({
  type: actionTypes.AUTH_SEND_USER_DATA_TO_REDUCER,
  payload,
})

export const authSignOutUser = () => ({ type: actionTypes.AUTH_SIGN_OUT_USER })
