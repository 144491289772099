import React from 'react'
import './PageTitle.sass'

function PageTitle({ title, counter, headerContent }) {
  return (
    <div className="PageTitle">
      <p className="PageTitle-Title">
        <span>{title}</span>
        {(counter || counter === 0) && (
          <span className="PageTitle-Counter">{counter}</span>
        )}
      </p>
      <div className="PageTitle-HeaderContent">{headerContent}</div>
    </div>
  )
}

export default PageTitle
