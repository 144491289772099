import getCategoriesOptions from '../getOptions/getCategoriesOptions'
import getCitiesOptions from '../getOptions/getCitiesOptions'
import getSubCategoriesOptions from '../getOptions/getSubCategoriesOptions'
import getParamsOptions from '../getOptions/getParamsOptions'
import useCallbacks from '../../hooks/useCallbacks'

const useSystemFieldsMethods = ({ form = null }) => {
  const methods = {
    getCategoriesOptions: () => getCategoriesOptions(),
    getParamsOptions: () => getParamsOptions(),
    getSubCategoriesOptions: () => handlers.getSubCategoriesOptions(),
    getCitiesOptions: () => getCitiesOptions(),
  }

  const handlers = useCallbacks((callbacks) => {
    callbacks.getSubCategoriesOptions = () => {
      if (form && form.category && form.category.value) {
        return getSubCategoriesOptions({ parentId: form.category.value })
      }
      return Promise.resolve([])
    }
  })

  return methods
}

export default useSystemFieldsMethods
