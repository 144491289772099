import React, { useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import handleSignInErrors from '@bit/ollapp.components.handle-sign-in-errors'
import NotificationBlock from '@bit/ollapp.components.notification-block'
import { firebase } from '@bit/ollapp.components.firebase'
import Button from '../../ui/Button/Button'
import { createForm } from '../../utils/forms/createForm'
import getFieldRenderObject from '../../utils/forms/render/getFieldRenderObject'
import isFormValid from '../../utils/forms/validation/isFormValid'
import getFormValues from '../../utils/forms/getFormValues'
import FormRender from '../../utils/forms/render/FormRender'

function SignIn({ ...router }) {
  const [form, setForm] = useState(
    createForm({
      formPattern: {
        email: {
          field: {
            fieldId: 'email',
            fieldType: 'input',
            inputType: 'email',
            validationPattern: 'email',
            label: 'Електронна пошта',
            placeholder: 'sample@gmail.com',
            required: true,
          },
          render: getFieldRenderObject(),
        },
        password: {
          field: {
            fieldId: 'password',
            fieldType: 'input',
            inputType: 'password',
            label: 'Пароль',
            placeholder: 'Створіть ваш пароль',
            required: true,
          },
          render: getFieldRenderObject(),
        },
      },
    })
  )

  const [showErrors, setShowErrors] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showResult, setShowResult] = useState({
    status: '',
    message: '',
    show: false,
  })

  const onSubmit = () => {
    if (!isFormValid({ form })) {
      return setShowErrors(true)
    }
    setIsLoading(true)
    setShowErrors(false)
    const values = getFormValues({ form })

    firebase
      .auth()
      .signInWithEmailAndPassword(values.email, values.password)
      .then(() => router.history.push('/settings'))
      .catch((e) => {
        setIsLoading(false)
        handleSignInErrors({
          form,
          updateForm: setForm,
          error: e,
          showErrors: setShowErrors,
        })
      })
  }

  return (
    <>
      {showResult.show && (
        <NotificationBlock
          type={showResult.status}
          message={showResult.message}
          dieAfter={5000}
        />
      )}
      <form
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit()
        }}
      >
        <div className="AuthForm-Fields">
          <FormRender
            sections={[{ fields: ['email', 'password'] }]}
            form={form}
            setForm={setForm}
            errors={showErrors}
          />
        </div>
        <div className="AuthForm-Buttons">
          <Button
            type="submit"
            title="Увійти"
            onClick={onSubmit}
            isLoading={isLoading}
            size={40}
          />
        </div>
      </form>
    </>
  )
}

export default withRouter(SignIn)
