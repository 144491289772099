import { isNull, find, cloneDeep, isEmpty, pick } from 'lodash'
import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react'
import Checkbox from '../../../../../ui/Field/Checkbox/Checkbox'
import Icon from '../../../../../ui/Icon/Icon'
import Spinner from '../../../../../ui/Spinner/Spinner'
import getCollection from '../../../../../utils/db/getCollection'
import { createForm } from '../../../../../utils/forms/createForm'
import FormRender from '../../../../../utils/forms/render/FormRender'
import getFormValues from '../../../../../utils/forms/getFormValues'
import getFieldRenderObject from '../../../../../utils/forms/render/getFieldRenderObject'
import promiseAllValues from '../../../../../utils/promiseAllValues'
import './ProductAvaliabilityManager.sass'

const ProductAvaliabilityManager = forwardRef(({ form }, ref) => {
  const [spots, setSpots] = useState(null)
  const [priceDb, setPriceDb] = useState(form.price.values)
  const activeSpot = find(spots, ['isActive', true])
    ? find(spots, ['isActive', true]).spotId
    : null

  useImperativeHandle(ref, () => ({
    getState() {
      const spotsToSync = spots
        .filter((s) => s.isSyncing && true)
        .map((s) => s.spotId)
      return pick(priceDb, spotsToSync)
    },
  }))

  useEffect(() => {
    promiseAllValues({
      cities: getCollection({ path: 'cities', docIdName: 'cityId' }),
      shops: getCollection({ path: 'shops', docIdName: 'shopId' }),
    }).then((result) => {
      const spotsArray = []
      result.shops.forEach((shop) => {
        shop.spots.forEach((spot) => {
          spotsArray.push({
            spotId: spot.spotId,
            spotCity: find(result.cities, ['cityId', spot.spotCity])
              ? find(result.cities, ['cityId', spot.spotCity]).title
              : 'Update city',
            spotAddress: spot.spotAddress,
            isSyncing: form.price.values.hasOwnProperty(spot.spotId),
            isActive: false,
          })
        })
      })
      const firstSyncSpot = find(spotsArray, ['isSyncing', true])

      if (firstSyncSpot) firstSyncSpot.isActive = true
      else spotsArray[0].isActive = true

      setSpots(spotsArray)
    })
  }, [])

  useEffect(() => {
    if (activeSpot) {
      if (!priceDb.hasOwnProperty(activeSpot)) {
        const priceDbClone = cloneDeep(priceDb)
        priceDbClone[activeSpot] = {
          productKitIs: [],
          priceRanges: [],
          spotId: activeSpot,
        }
        setPriceDb(priceDbClone)
      }
    }
  }, [activeSpot])

  const onKitIsClicked = (option) => {
    const priceDbClone = cloneDeep(priceDb)
    if (priceDbClone[activeSpot].productKitIs.includes(option)) {
      priceDbClone[activeSpot].productKitIs = priceDbClone[
        activeSpot
      ].productKitIs.filter((o) => o !== option)
    } else {
      priceDbClone[activeSpot].productKitIs.push(option)
    }
    setPriceDb(priceDbClone)
  }

  const syncPriceRangeForm = (priceRangeForm, pos) => {
    const activeSpotId = find(spots, ['isActive', true]).spotId
    const priceDbClone = cloneDeep(priceDb)
    priceDbClone[activeSpotId].priceRanges = !isEmpty(
      priceDbClone[activeSpotId].priceRanges
    )
      ? priceDbClone[activeSpotId].priceRanges.map((r, i) => {
          if (i === pos) return priceRangeForm
          return r
        })
      : [priceRangeForm]
    setPriceDb(priceDbClone)
  }

  const onOptionRemove = (pos) => {
    const activeSpotId = find(spots, ['isActive', true]).spotId
    const priceDbClone = cloneDeep(priceDb)
    priceDbClone[activeSpotId].priceRanges.splice(pos, 1)
    setPriceDb(priceDbClone)
  }

  const addNewRange = () => {
    const priceDbClone = cloneDeep(priceDb)
    const priceRangeObject = {}
    Object.keys(priceDbClone[activeSpot].priceRanges[0]).forEach((k) => {
      priceRangeObject[k] = ''
    })
    priceDbClone[activeSpot].priceRanges.push(priceRangeObject)
    setPriceDb(priceDbClone)
  }

  const onSpotClicked = (spotId) => {
    const spotsClone = cloneDeep(spots)
    setSpots(
      spotsClone.map((spot) => {
        spot.isActive = spot.spotId === spotId
        return spot
      })
    )
  }
  const onSpotSyncClicked = (spotId) => {
    const spotsClone = cloneDeep(spots)
    const spot = find(spotsClone, ['spotId', spotId])
    spot.isSyncing = !spot.isSyncing
    setSpots(spotsClone)
  }

  return !isNull(spots) && activeSpot && !isEmpty(priceDb) ? (
    <div className="ProductAvaliabilityManager">
      <div className="LeftSide">
        <h4>Наявність та ціни</h4>
        <div className="LocationsList">
          {spots.map((spot) => (
            <ListItem
              {...spot}
              onSpotClicked={onSpotClicked}
              onSpotSyncClicked={onSpotSyncClicked}
            />
          ))}
        </div>
      </div>
      <div className="RightSide">
        {priceDb[activeSpot] ? (
          <>
            <div className="PAM-Header">
              <span className="Title Body-Bold-2">Комплект це:</span>
              <Checkbox
                label="Головний"
                value={
                  priceDb[activeSpot].productKitIs.includes('main')
                    ? 'on'
                    : 'off'
                }
                onClick={() => onKitIsClicked('main')}
              />
              {form.options.values.map((v, i) => (
                <Checkbox
                  label={`Опція ${i}`}
                  value={
                    priceDb[activeSpot].productKitIs.includes(`option${i}`)
                      ? 'on'
                      : 'off'
                  }
                  onClick={() => onKitIsClicked(`option${i}`)}
                />
              ))}
            </div>
            <div className="PAM-Body">
              <div className="BodyTitle">
                {form.options.values.map((v, i) => (
                  <div className="OptionTitle Body-Regular-2">
                    {`Опція ${i}`}
                  </div>
                ))}
              </div>
              <div
                key={activeSpot}
                className="PAM-BodyOptions"
                style={{
                  gridTemplateColumns: `repeat(${
                    isEmpty(priceDb[activeSpot].priceRanges)
                      ? 2
                      : priceDb[activeSpot].priceRanges.length + 1
                  }, 136px)`,
                }}
              >
                {isEmpty(priceDb[activeSpot].priceRanges) ? (
                  <BodyOption
                    formData={priceDb[activeSpot]}
                    pos={0}
                    options={form.options.values}
                    syncForm={(priceRangeForm) =>
                      syncPriceRangeForm(priceRangeForm, 0)
                    }
                  />
                ) : (
                  priceDb[activeSpot].priceRanges.map((r, i) => (
                    <BodyOption
                      formData={priceDb[activeSpot]}
                      pos={i}
                      options={form.options.values}
                      syncForm={(priceRangeForm) =>
                        syncPriceRangeForm(priceRangeForm, i)
                      }
                      onOptionRemove={() => onOptionRemove(i)}
                    />
                  ))
                )}

                <div className="BodyOption-AddRange" onClick={addNewRange}>
                  <Icon name="plus" />
                </div>
              </div>
            </div>
          </>
        ) : (
          <Spinner type="popup" />
        )}
      </div>
    </div>
  ) : (
    <Spinner type="popup" />
  )
})

function ListItem({
  spotId,
  spotCity,
  spotAddress,
  isActive,
  isSyncing,
  onSpotClicked,
  onSpotSyncClicked,
}) {
  return (
    <div
      className={[
        'ListItem',
        ...(isActive ? ['ListItem_theme_active'] : []),
      ].join(' ')}
    >
      <Checkbox
        onClick={() => onSpotSyncClicked(spotId)}
        value={isSyncing ? 'on' : 'off'}
      />
      <p className="Title" onClick={() => onSpotClicked(spotId)}>
        {`${spotCity}, ${spotAddress}`}
      </p>
    </div>
  )
}

function BodyOption({ formData, pos, options, syncForm, onOptionRemove }) {
  const pattern = {
    days: {
      field: {
        fieldId: 'days',
        fieldType: 'input',
        inputType: 'text',
        placeholder: 'Дні',
      },
      render: getFieldRenderObject(),
    },
    productPrice: {
      field: {
        fieldId: 'productPrice',
        fieldType: 'input',
        inputType: 'text',
        placeholder: 'Ціна',
      },
      render: getFieldRenderObject(),
    },
    kitPrice: {
      field: {
        fieldId: 'kitPrice',
        fieldType: 'input',
        inputType: 'text',
        placeholder: 'Ціна набору',
      },
      render: getFieldRenderObject(),
    },
  }

  const [form, setForm] = useState(null)
  const [fields, setFields] = useState([])

  useEffect(() => {
    const patternClone = cloneDeep(pattern)
    options.forEach((o, i) => {
      patternClone[`option${i}Price`] = {
        field: {
          fieldId: `option${i}`,
          fieldType: 'input',
          inputType: 'text',
          placeholder: 'Ціна',
        },
        render: getFieldRenderObject(),
      }
    })
    setForm(
      createForm({
        formPattern: patternClone,
        formData: formData ? formData.priceRanges[pos] : {},
      })
    )
  }, [options])

  useEffect(() => {
    setFields([
      'days',
      'productPrice',
      ...options.map((o, i) => `option${i}Price`),
      'kitPrice',
    ])
    if (form) {
      const values = getFormValues({ form })
      syncForm(values)
    }
  }, [form, options])

  return (
    <div className="BodyOption">
      <p className="Title Body-Regular-2">
        Діапазон {pos}
        {pos > 0 && (
          <span className="RemovePriceRange" onClick={onOptionRemove}>
            <Icon name="trash" weight="regular" />
          </span>
        )}
      </p>
      <FormRender sections={[{ fields }]} form={form} setForm={setForm} />
    </div>
  )
}

export default ProductAvaliabilityManager
