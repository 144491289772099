import React from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import './DatePicker.sass'
import Picker from 'react-datepicker'
import uk from 'date-fns/locale/uk'

const DatePicker = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} className="DatePicker">
      <Picker {...props} locale={uk} inline />
    </div>
  )
})

export default DatePicker
