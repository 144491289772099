import React, { useState, useRef, useEffect } from 'react'
import './CategoryColumn.sass'
import usePopUp from '@bit/ollapp.components.use-pop-up'
import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'
import sortBy from 'lodash/sortBy'
import Spinner from '@bit/ollapp.components.spinner'
import { db } from '@bit/ollapp.components.firebase'
import OptionsMenu from '../../../../../ui/OptionsMenu/OptionsMenu'
import OptionsButton from '../../../../../ui/Table/SceneTable/OptionsButton'
import ManageCategoryPopUp from './ManageCategoryPopUp'

function CategoryColumn({
  title,
  categoryId,
  parentCategoryId,
  categories,
  addOption = false,
  editOptions = false,
  onCategoryClicked,
}) {
  const optButRef = useRef()
  const ddBRef = useRef()

  const [showEditOptions, setShowEditOptions] = usePopUp(optButRef, ddBRef)
  const [showPopUpFor, setShowPopUpFor] = useState(null)

  useEffect(() => {
    setShowEditOptions(false)
  }, [showPopUpFor])

  return (
    <>
      {showPopUpFor && (
        <ManageCategoryPopUp
          categoryId={showPopUpFor.categoryId}
          parentCategoryId={parentCategoryId}
          close={() => setShowPopUpFor(null)}
        />
      )}

      <div className="CategoryColumn">
        <div className="CategoryColumn-Header">
          <span className="CategoryColumn-HeaderTitle">{title}</span>
          <div className="CategoryColumn-OptionsButton">
            {addOption ? (
              <OptionsButton
                icon="plus"
                clicked={() => setShowPopUpFor({ categoryId: null })}
              />
            ) : editOptions ? (
              <>
                <OptionsButton ref={optButRef} />
                {showEditOptions && (
                  <OptionsMenu
                    ref={ddBRef}
                    styles={{ minWidth: '120px' }}
                    options={[
                      {
                        icon: 'plus',
                        label: 'Додати',
                        clicked: () => setShowPopUpFor({ categoryId: null }),
                      },
                      {
                        icon: 'pencil-alt',
                        label: 'Редаг.',
                        clicked: () => setShowPopUpFor({ categoryId }),
                      },
                      ...(isEmpty(categories)
                        ? [
                            {
                              icon: 'trash-alt',
                              label: 'Видалити',
                              clicked: () =>
                                db
                                  .collection('config/products/categories')
                                  .doc(categoryId)
                                  .delete(),
                            },
                          ]
                        : []),
                    ]}
                  />
                )}
              </>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="CategoryColumn-Content">
          {isNull(categories) ? (
            <Spinner type="popup" />
          ) : isEmpty(categories) ? (
            <p className="CategoryColumn-EmptyContent">Немає категорій</p>
          ) : (
            sortBy(categories, ['title']).map((c) => (
              <p
                className={[
                  'CategoryColumn-CategoryElement',
                  ...(c.isActive
                    ? ['CategoryColumn-CategoryElement_theme_active']
                    : []),
                ].join(' ')}
                onClick={() => onCategoryClicked(c.categoryId)}
              >
                {c.title}
              </p>
            ))
          )}
        </div>
      </div>
    </>
  )
}

export default CategoryColumn
