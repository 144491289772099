import React from 'react'
import './Layout.sass'
import { Route } from 'react-router-dom'
import SideNavBar from '../../ui/SideNavBar/SideNavBar'
// import Header from '../../ui/Header/Header'
// import Analytics from '../../modules/Analytics/Analytics'
// import Payments from '../../modules/Payments/Payments'
// import Clients from '../../modules/Clients/Clients'
import Orders from '../../modules/Orders/Orders'
// import Feeds from '../../modules/Feeds/Feeds'
import Products from '../../modules/Products/Products'
import Partners from '../../modules/Partners/Partners'
// import Employees from '../../modules/Employees/Employees'
// import Conversations from '../../modules/Conversations/Conversations'
import Settings from '../../modules/Settings/Settings'

function Layout() {
  return (
    <div className="Layout">
      <SideNavBar />
      {/* <Header /> */}
      <div className="Layout-Content">
        {/* <Route path="/analytics" exact component={Analytics} /> */}
        {/* <Route path="/payments" exact component={Payments} /> */}
        {/* <Route path="/clients" component={Clients} /> */}
        <Route path="/orders" component={Orders} />
        {/* <Route path="/feeds" component={Feeds} /> */}
        {/* <Route path="/employees" component={Employees} /> */}
        {/* <Route path="/conversations" component={Conversations} /> */}
        <Route path="/partners" component={Partners} />
        <Route path="/products" component={Products} />
        <Route path="/settings" component={Settings} />
      </div>
    </div>
  )
}

export default Layout
