import { getMonth } from 'date-fns'
import {
  formatDateForDateChooser,
  formatDateWithMonthForDateChooser,
} from '../../../../../utils/date/dateFormat'

const getFormattedValue = ({ start, end, sameDay = false }) => {
  if (start && end) {
    const startMonth = getMonth(start)
    const endMonth = getMonth(end)
    if (startMonth === endMonth) {
      return formatDateForDateChooser(start, end, sameDay)
    }
    return formatDateWithMonthForDateChooser(start, end, sameDay)
  }
  return null
}

export default getFormattedValue
